<template>
  <div class="container kuan">
    <div class="main">
      <topTimer />
      <div class="kd_flexcontainer">
        <!-- 下注框 -->
        <div class="order_wrap">
          <div class="player listmain">
            <div class="data">
              <h3>下注框<button class="tuima_btn">退码</button></h3>
              <div class="inside-os">
                <div class="report">
                  <table class="com-table">
                    <thead>
                      <tr>
                        <th width="150px">注单编号</th>
                        <th>号码</th>
                        <th>赔率</th>
                        <th>金额</th>
                        <!-- <th>状态</th> -->
                        <th class="norightbor">
                          <span>全选</span>
                          <input
                            type="checkbox"
                            style="position: relative; top: 2px"
                          />
                        </th>
                        <th v-if="wjlist.length>12" style="width: 17px"></th>
                      </tr>
                    </thead>

                    <tbody id="tbody">
                      <tr v-for="item in wjlist" :key="item.orderid">
                        <td width="150px">{{item.touzhufangshi}}{{ item.orderid }}</td>
                        <td>{{ item.iteminfo }}</td>
                        <td><span class="red">{{ item.winodds }}</span></td>
                        <td>{{ item.amount }}</td>
                        <!-- <td>好</td> -->
                        <td>
                          <input type="checkbox" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 停押号码 -->
        <div class="ty_wrap">
          <div class="player listmain">
            <div class="data">
              <h3>
                目前停押号码
                <select name="" id="">
                  <option value="222222">22222</option>
                  <option value="11111">11111</option>
                </select>
              </h3>
              <div class="inside-os">
                <div class="report">
                  <table class="com-table">
                    <thead>
                      <tr>
                        <th>号码</th>
                        <th>金额</th>
                        <th class="norightbor">全选</th>
                        <th width="17px"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="i in 200">
                        <td>XXX12</td>
                        <td>1234567</td>
                        <td>
                          <input type="checkbox" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 删出停押号码保留区 -->
        <div class="bl_wrap">
          <div class="player listmain">
            <div class="data">
              <h3>删除停押号码保留区</h3>
              <div class="inside-os">
                <div class="report">
                  <table class="com-table">
                    <thead>
                      <tr>
                        <th>号码</th>
                        <th class="norightbor">金额</th>
                        <th width="17"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="i in 300">
                        <td>XX12345</td>
                        <td>123445</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- ------------ -->
      </div>
      <!-- ---------------------- -->
      <div class="player listmain">
        <div class="data">
          <h3>
            <input
              type="checkbox"
              :checked="isxian"
              @change="isxianchange"
            />四字现
            <input
              type="checkbox"
              :checked="quanzhuan"
              @change="quanzhuanchange"
            />全转
          </h3>
          <section class="xiazhukuang">
            <h1 style="margin-left: 10px">号码</h1>
            <input
              class="xiazhulabel"
              ref="haoma"
              id="haoma"
              type="text"
              maxlength="4"
              @change="haomachange1"
              @input="haomachange"
              v-model="haoma"
            />
            <!-- {{haomas}} -->
            <h1 style="margin-left: 5px">金额</h1>
            <input
              class="xiazhulabel"
              ref="amount"
              v-model="amount"
              type="number"
            />
            <button class="xzbtn" @click="xiazhuon">确定下注</button>
            <!-- <h1>角票</h1>
        <h1 class="green">123</h1> -->
            <h1 style="margin-left: 5px">赔率:</h1>
            <h1 class="red">{{ Odds }}</h1>
            <h1 style="margin-left: 5px">可下:</h1>
            <h1 class="red">{{ currentQianbao.amount }}</h1>
          </section>
        </div>
      </div>

      <!-- ---------------------------- -->
    </div>
  </div>
</template>

<script>
import topTimer from "../mainHeader.vue";
import yushe from "../yushe.vue";
import { mapState } from "vuex";
import mixins from "../mixins/mainMixins";
import { dingArr, xianArr,ding2,ding3,ding4 } from "./wxcalldata";


export default {
  name: "kuaida",
  props: [""],
  mixins: [mixins],
  components: {
    yushe,
    topTimer,
  },
  data() {
    return {
      amount: "",
      Odds: "",
      setorderArr: [],
      isxian: false,
      quanzhuan: false,
      haoma: "",
      isDui: false,
      state: 0,
      wjlist:[]
    };
  },
  computed: {
    ...mapState(["userInfo", "currentGame",'currentQianbao']),
    xcount() {
      let count = 0;
      if (this.haoma.includes("X")) {
        var regex = new RegExp("X", "g");
        count = this.haoma.match(regex).length;
      }

      return count;
    },
  },
  watch: {
    haoma(val) {
      if (val.length == 4) {
        this.shaixuanhaoma();
      }
    },
    quanzhuan() {
      if (this.quanzhuan) {
        this.isxian = false;
      }
    },
    isxian() {
      if (this.isxian) {
        this.quanzhuan = false;
      }
    },
  },

  created() {},

  methods: {
    // 获取未接明细
    getweijieList() {
   
      let hours = new Date().getHours();
      let begindate = null;
      let enddate = null;
      if (hours < 6) {
        begindate = this.splitDate(this.getBeforeDate(1));
        enddate = this.splitDate(this.getBeforeDate(1));
      } else {
        begindate = this.splitDate(this.getBeforeDate(0));
        enddate = this.splitDate(this.getBeforeDate(0));
      }
     
      let obj = {
        uuid: this.userInfo.Uuid,
        sid: this.userInfo.Sid,
        state: this.state,
        pagenum: 1,
        pagecount: 50,
        begindate,
        enddate,
        roomeng:this.currentGame.roomeng
      };
      this.$http
        .post("getlotorderinfos", obj)
        .then((res) => {
          if (res.Status) {
            if (res.Msg) {
              let resObj = JSON.parse(res.Msg);
           
              this.wjlist = resObj.data;
              if(this.wjlist.length>12){
                var tbody = document.getElementById("tbody");
tbody.scrollTop = tbody.scrollHeight;

              }
           
           
            }else{
              this.list=[]
            }
          } else {
            this.$message.error(res.Msg);
          }
        })
        .catch((err) => {});
    },
    xiazhuon() {
      if (this.setorderArr.length > 0) {
        if (!this.amount) {
          this.$message.error("请输入下注金额");
          return;
        }

        // arrbet: [{"id":5459,"money":20},{"id":5460,"money":20}]
        let obj = {
          uuid: this.userInfo.Uuid,
          sid: this.userInfo.Sid,
          roomeng: this.currentGame.roomeng,
          pan: this.pan,
          arrbet: [],
        };
        let zhushu=this.setorderArr.length;
        this.setorderArr.forEach((item) => {
          let objbet = {
            id: item.ResultID,
            money: Math.trunc(Number(this.amount)),
          };
          obj.arrbet.push(objbet);
        });
      
        obj.arrbet = JSON.stringify(obj.arrbet);
        this.$http.post("setneworder", obj).then((res) => {
          if (res.Status) {
            this.haoma='';
            this.setorderArr=[]
            let arr = JSON.parse(res.Msg);
            arr.forEach(item=>{
            item.label=this.bianXXXX(item)
            })
            this.$store.commit('setwxcArr',[...arr])
            this.$message.success('注单成功'+zhushu+'注');
            this.getweijieList()
          } else {
            this.$message.error(res.Msg);
          }
        });
      } else {
        this.$message.warning("请输入数据");
      }
    },
    shaixuanhaoma() {
      this.setorderArr = [];
      if (this.xcount >= 3) {
        this.selectInputContent();
        this.$message.warning({
          message: "号码格式有误",
          offset: 300,
        });
        return;
      }
      if (this.xcount > 0 && this.haoma.length < 4) {
        this.selectInputContent();
        this.$message.warning({
          message: "号码格式有误",
          offset: 300,
        });
        return;
      }
      if (this.haoma.length < 4) {
        // 长度小于 是现
        let strArr = this.haoma.split("");
        strArr.sort((a, b) => Number(a) - Number(b));
        let xianlabel = strArr.join("");
        let item = xianArr.find((item) => item.label == xianlabel);
        if (item) {
          // console.log(item, "现");
          this.setorderArr.push(item);
          this.$refs.amount.focus();
        }
      } else {
        // 四字线 和 全传都没有选中
        if (!this.isxian && !this.quanzhuan) {
          // 长度是4 定
          if (this.haoma.length == 4) {
            let item = dingArr.find((item) => item.label == this.haoma);
            if (item) {
              // console.log(item, "定");
              this.setorderArr.push(item);
              this.$refs.amount.focus();
            }
          }
        }
        if (this.isxian) {
          
          if (this.xcount > 0) {
            this.selectInputContent();
            this.$message.warning({
              message: "号码格式有误",
              offset: 300,
            });
            return;
          }
          // 长度小于 是现
          let strArr = this.haoma.split("");
          strArr.sort((a, b) => Number(a) - Number(b));
          let xianlabel = strArr.join("");
          let item = xianArr.find((item) => item.label == xianlabel);
          if (item) {
            // console.log(item, "四字现");
            this.setorderArr.push(item);
            this.$refs.amount.focus();
          }
        }
        if (this.quanzhuan) {
          if(this.xcount==0){
            let strArr = this.haoma.split("");
            ding4.forEach((item) => {
            let labelD = item.label.split("");
            let labelNumber = labelD.filter((lele) => this.isNumber(lele));
            let b = this.isContained(strArr, labelNumber);
            if (b) {
              this.setorderArr.push(item);
             
            }
          });
          }else if(this.xcount==1){
            let strArr = this.haoma.split("");
            ding3.forEach((item) => {
            let labelD = item.label.split("");
            let labelNumber = labelD.filter((lele) => this.isNumber(lele));
            let b = this.isContained(strArr, labelNumber);
            if (b) {
              this.setorderArr.push(item);
             
            }
          });
          }else if(this.xcount==2){
            let strArr = this.haoma.split("");
            ding2.forEach((item) => {
            let labelD = item.label.split("");
            let labelNumber = labelD.filter((lele) => this.isNumber(lele));
            let b = this.isContained(strArr, labelNumber);
            if (b) {
              this.setorderArr.push(item);
             
            }
          });
          }
     
          this.$refs.amount.focus();
         

        
        }
      }
      if (this.setorderArr.length > 0) {
        this.getOddsFororder();
      }
    },

    getOddsFororder() {
      let obj = {
        uuid: this.userInfo.Uuid,
        sid: this.userInfo.Sid,
        roomeng: this.currentGame.roomeng,
        pan: this.pan,
        itemid: this.setorderArr[0].ResultID,
      };
      // getitemodds
      this.$http.post("getcommonitemodds", obj).then((res) => {
        if (res.Status) {
          let obj = JSON.parse(res.Msg);

          this.Odds = obj[0].Odds;
        }
      });
    },
    haomachange1() {
      if (this.haoma && this.haoma.length < 4) {
        this.shaixuanhaoma();
      } else {
        this.isDui = false;
      }
    },
    selectInputContent() {
      this.$nextTick(() => {
        let haomaInput = document.getElementById("haoma");
        haomaInput.select();
      });
    },
    haomachange(e) {
      let val = e.target.value;
      this.haoma = val.replace(/[^\d]/g, "X");
    },
    quanzhuanchange(e) {
      let bool = e.target.checked;
      this.quanzhuan = bool;
      if(bool){
        this.shaixuanhaoma();
      }
    },
    isxianchange(e) {
      let bool = e.target.checked;
      this.isxian = bool;
      if(bool){
        this.shaixuanhaoma();
      }
    },
    bianXXXX(item){
      // console.log(item.gamedalei)
      let label=''
      let oldlabel=item.iteminfo;
      let labelArr=oldlabel.split('-');
      // console.log(labelArr)
      if(item.gamedalei=='一字定'){
        if(labelArr[0]=='千'){
       label=labelArr[1]+'XXXX'
      }else if(labelArr[0]=='百'){
        label='X'+labelArr[1]+'XXX'
      }else if(labelArr[0]=='拾'){
        label='XX'+labelArr[1]+'XX'
      }else if(labelArr[0]=='个'){
        label='XXX'+labelArr[1]+'X'
      }else if(labelArr[0]=='五'){
        label='XXXX'+labelArr[1]
      }
      }else if(item.gamedalei=='二字定'){
        if(labelArr[0]=='千百'){
          label=labelArr[1]+'XX'
        }else if(labelArr[0]=='千拾'){
          label=labelArr[1][0]+'X'+labelArr[1][1]+'X'
        }else if(labelArr[0]=='千个'){
          label=labelArr[1][0]+'XX'+labelArr[1][1]
        }else if(labelArr[0]=='百个'){
          label='X'+labelArr[1][0]+'X'+labelArr[1][1]
        }else if(labelArr[0]=='百拾'){
          label='X'+labelArr[1][0]+labelArr[1][1]+'X'
        }else if(labelArr[0]=='拾个'){
          label='XX'+labelArr[1][0]+labelArr[1][1]
        }else if(labelArr[0]=='千五'){
          label=labelArr[1][0]+'XXX'+labelArr[1][1]
        }else if(labelArr[0]=='百五'){
          label='X'+labelArr[1][0]+'XX'+labelArr[1][1]
        }else if(labelArr[0]=='拾五'){
          label='XX'+labelArr[1][0]+'X'+labelArr[1][1]
        }else if(labelArr[0]=='个五'){
          label='XXX'+labelArr[1]
        }
      }else if(item.gamedalei=='三字定'){
        if(labelArr[0]=='千百拾'){
          label=labelArr[1]+'X'
        }else if(labelArr[0]=='千百个'){
          label=labelArr[1][0]+labelArr[1][1]+'X'+labelArr[1][2]
        }else if(labelArr[0]=='千拾个'){
          label=labelArr[1][0]+'X'+labelArr[1][1]+labelArr[1][2]
        }else if(labelArr[0]=='百拾个'){
          label='X'+labelArr[1]
        }
      }else if(item.gamedalei=='四字定'){
        label=labelArr[1]
      }else if(item.gamedalei=='二字现'){
        label=labelArr[1]
      }else if(item.gamedalei=='三字现'){
        label=labelArr[1]
      }else if(item.gamedalei=='四字现'){
        label=labelArr[1]
      }
      
     
  
 
    return label
    },
    isContained(a, b) {
      //a是否包含b
      let numArrA = a.reduce(function (prev, next) {
        prev[next] = prev[next] + 1 || 1;

        return prev;
      }, {});
      let numArrB = b.reduce(function (prev, next) {
        prev[next] = prev[next] + 1 || 1;

        return prev;
      }, {});
      // a和b其中一个不是数组，直接返回false
      if (!(a instanceof Array) || !(b instanceof Array)) return false;
      const len = b.length;
      // a的长度小于b的长度，直接返回false
      if (a.length < len) return false;
      for (let i = 0; i < len; i++) {
        // 遍历b中的元素，遇到a没有包含某个元素的，直接返回false
        if (!a.includes(b[i])) {
          return false;
        } else {
          // 判断 a数组中包含B并且 数量要大于b数组中元素重复次数
          if (numArrA[b[i]] < numArrB[b[i]]) {
            return false;
          }
        }
      }

      // 遍历结束，返回true
      return true;
    },
  },
};
</script>
<style  scoped>
.xiazhulabel.redbor {
  border: 2px solid #ab243d;
}
.listmain > .data > h3 input {
  position: relative;
  top: 2px;
  margin-right: 5px;
}
.listmain > .data > h3 {
  text-align: left;
  box-sizing: border-box;
  padding-left: 10px;
}
.xiazhukuang {
  font-weight: bold;
}
h3 {
  position: relative;
}
.container {
  position: relative;
}
table tbody {
  display: block;

  max-height: 324px;
  overflow-x: hidden;
  overflow-y: auto;
}

table thead,
tbody tr {
  display: table;

  width: 100%;
  height: 26px;
  table-layout: fixed;
}
/* table thead {
  position: relative;
  overflow-y: scroll;
 } */
.tuima_btn {
  width: 40px;
  height: 22px;
  position: absolute;
  top: 1px;
  right: 43px;
  background: #bd0a2b;
  outline: none;
  color: #fff;
  border: none;
}
.tuima_btn:hover {
  background: #ab243d;
}
.com-table tr,
td,
th {
  position: relative;
  min-height: 26px;
  text-align: center;
  vertical-align: middle;
}

.com-table {
  width: 100%;
}
.inside-os {
  top: 0;
  width: auto !important;
  min-width: 100%;
  padding: 0;
}
.bl_wrap {
  flex: 1;
}
.ty_wrap {
  flex: 1.5;
}
.h3title {
  height: 26px;
  text-align: center;
  line-height: 26px;
  color: #fff;
}
.skin-blue .h3title {
  background: #2161b3;
}
.skin-brown .h3title {
  background: #a18361;
}
.skin-red .h3title {
  background: #c2455c;
}
.order_wrap {
  flex: 3;
}
.kd_flexcontainer > div {
  margin: 0 4px 0 0;
}
.kd_flexcontainer > div:nth-of-type(3) {
  margin-right: 0;
}
.kd_flexcontainer {
  margin-top: 4px;
  width: 100%;

  display: flex;
}
</style>